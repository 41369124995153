import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar, Badge, Tooltip } from 'antd'
import styles from './style.module.scss'

const mapStateToProps = ({ dispatch, user, settings }) => ({ dispatch, user, settings })

const ProfileMenu = ({ dispatch, user, settings }) => {
  const [count, setCount] = useState(7)

  const logout = e => {
    e.preventDefault()
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  const addCount = () => {
    setCount(count + 1)
  }
  const {theme} = settings;

  const setTheme = nextTheme => {
    console.log(theme)
    console.log(nextTheme)
    dispatch({
      type: 'settings/SET_THEME',
      payload: {
        theme: nextTheme,
      },
    })
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'menuColor',
        value: nextTheme === 'dark' ? 'dark' : 'light',
      },
    })
  }

  const menu = (
    <Menu selectable={false}>
      <Menu.Item>
        <a href="#/user/profile">
          <strong>
            {/* <FormattedMessage id="topBar.profileMenu.hello" />, {user.firstName || 'Anonymous'} */}
            <FormattedMessage id="topBar.profileMenu.hello" />, {user.firstName || 'Anonymous'} {user.lastName || ' '}
          </strong>
          {/* <div>
            <strong className="mr-1">
              <FormattedMessage id="topBar.profileMenu.billingPlan" />:{' '}
            </strong>
            Professional
          </div> */}
          <div>
            <strong>
              <FormattedMessage id="topBar.profileMenu.role" />:{' '}
            </strong>
            {user.role || '—'}
          </div>
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <div>
          <strong>
            <FormattedMessage id="topBar.profileMenu.email" />:{' '}
          </strong>
          {user.email || '—'}
          <br />
          <strong>
            <FormattedMessage id="topBar.profileMenu.phone" />:{' '}
          </strong>
          {user.mobile || '—'}
        </div>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <Tooltip title="Switch Dark / Light Theme" placement="left">
          <a
            role="button"
            tabIndex="0"
            onFocus={e => {
              e.preventDefault()
            }}
            onKeyPress={() => setTheme(theme === 'default' ? 'dark' : 'default')}
            onClick={() => setTheme(theme === 'default' ? 'dark' : 'default')}
            style={{ bottom: 'calc(50% + 60px)' }}
            // className={style.air__sidebar__toggleButton}
          >
            {theme === 'default' && <><i className="fe fe-moon" /> Dark Theme</>}
            {theme !== 'default' && <><i className="fe fe-sun" /> Light Theme</>}
          </a>
        </Tooltip>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        {/* <Redirect to="/auth/404" /> */}
        <a href="#/user/profile">
          <i className={`${styles.menuIcon} fe fe-user`} />
          <FormattedMessage id="topBar.profileMenu.editProfile" />
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item>
        <a href="#" onClick={logout}>
          <i className={`${styles.menuIcon} fe fe-log-out`} />
          <FormattedMessage id="topBar.profileMenu.logout" />
        </a>
      </Menu.Item>
    </Menu>
  )
  return (
    <Dropdown overlay={menu} trigger={['click']} onVisibleChange={addCount}>
      <div className={styles.dropdown}>
        {/* <Badge count={count}> */}
        {/* <Avatar className={styles.avatar} shape="square" size="large" icon={<UserOutlined />} /> */}
        <Badge>
          <img src={user.image || "resources/images/avatars/avatar.png"} alt={`${user.firstName || 'Anonymous'} ${user.lastName}`} style={{maxWidth:'42px', maxHeight:'42px'}} />
        </Badge>
      </div>
    </Dropdown>
  )
}

export default connect(mapStateToProps)(ProfileMenu)
