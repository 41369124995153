import firebase from 'firebase/app'
import { notification } from 'antd'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'

// const firebaseConfig = {
//   apiKey: 'AIzaSyBNiOG-W5v9LuH2ULmY0tI6e_Db9j9on6o',
//   authDomain: 'upbrand2.firebaseapp.com',
//   databaseURL: 'https://upbrand2.firebaseio.com',
//   projectId: 'upbrand2',
//   storageBucket: 'upbrand2',
//   messagingSenderId: '277852434213',
//   appId: '1:277852434213:web:ed7e608a679110ba2bf4cc',
//   clientID: '277852434213-t8gjcggvksf8g8td25smmr7k8l98nnk5.apps.googleusercontent.com',
//   clientSecret: 'CwLSzH5rGJznO_mDdCdrlniO',
// }
const firebaseConfig = {
  apiKey: "AIzaSyB-a2e24RB9QissRxzbYIQTBlhikyW8QzM",
  authDomain: "ballweb7.firebaseapp.com",
  databaseURL: "https://ballweb7.firebaseio.com",
  projectId: "ballweb7",
  storageBucket: "ballweb7.appspot.com",
  messagingSenderId: "72439095968",
  appId: "1:72439095968:web:d05e718903a40f5b8d9fc5",
  measurementId: "G-6CY1GWXGGB"
};
firebase.initializeApp(firebaseConfig)
export const fb = firebase;
export const auth = firebase.auth()
export const db = firebase.firestore()
export const rdb = firebase.database()
export const storage = firebase.storage()

export async function login(email, password) {
  return auth
    .signInWithEmailAndPassword(email, password)
    .then(() => true)
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function register(email, password, firstName, lastName, mobile) {
  return registerWithRole(email, password, firstName, lastName, mobile, "member")
}
export async function registerWithRole(email, password, firstName, lastName, mobile, role) {
  return auth
    .createUserWithEmailAndPassword(email, password)
    .then(response => {
      if (response.user) {
        const { uid } = response.user
        rdb
          .ref('users')
          .child(uid)
          .set({
            "role": role,
            firstName, 
            lastName, 
            mobile,
            email,
            createDate: fb.database.ServerValue.TIMESTAMP,
          })
      }
      return true
    })
    .catch(error => {
      notification.warning({
        message: error.code,
        description: error.message,
      })
    })
}

export async function currentAccount() {
  let userLoaded = false
  function getCurrentUser(authUser) {
    return new Promise((resolve, reject) => {
      if (userLoaded) {
        resolve(authUser.currentUser)
      }
      const unsubscribe = authUser.onAuthStateChanged(user => {
        userLoaded = true
        unsubscribe()
        const getUserData = async () => {
          if (user) {
            const userFields = await rdb
              .ref('users')
              .child(user.uid)
              .once('value')
              .then(snapshot => {
                return snapshot.val()
              })
            const mergedUser = Object.assign(user, {
              id: user.uid,
              firstName: userFields.firstName,
              lastName: userFields.lastName,
              mobile: userFields.mobile,
              role: userFields.role,
              // avatar: user.photoUrl,
              image: userFields.image,
            })
            // console.log("mergedUser = ",mergedUser)
            return mergedUser
          }
          return user
        }
        resolve(getUserData())
      }, reject)
    })
  }
  return getCurrentUser(auth)
}

export async function logout() {
  return auth.signOut().then(() => true)
}


export async function doGetUserProfile() {
  // db.collection("users")
  //   .onSnapshot(function(data){
  //     console.log("fetch "+"users"+" size = ", data.docs.length);
  // });
  const docRef = db.collection("users").doc("c");
  docRef.get().then(function(doc) {
      if (doc.exists) {
          console.log("Document data:", doc.data());
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }
  }).catch(function(error) {
      console.log("Error getting document:", error);
  });
}

// auth ########################################################################

// Sign Up
// export const doCreateUserWithEmailAndPassword = (email, passwordone, n, u) =>
//   auth.createUserWithEmailAndPassword(email, passwordone).then(resp => {
//     return db
//       .collection("users")
//       .doc(u)
//       .set({
//         name: n,
//         username: u,
//         email: email,
//         creationDate: new Date().toISOString(),
//         userID: resp.user.uid
//       });
//   });

// export const checkUserNameAvalaibility = un =>
//   db
//     .doc(`/users/${un}`)
//     .get()
//     .then(doc => {
//       if (doc.exists) {
//         return false;
//       } else {
//         return true;
//       }
//     });

// export const doCreateUserWithEmailAndPassword = (email, passwordone, n, u) =>
//   db.doc(`/users/${u}`).get()
//   .then(doc => {
//       if(doc.exists){
//           return alert("user name already taken");
//       } else {
//           return auth().createUserWithEmailAndPassword(email, passwordone, n, u).then(resp => {
//                 return db
//                   .collection("users")
//                   .doc(u)
//                   .set({
//                     name: n,
//                     username: u,
//                     email: email,
//                     creationDate:new Date().toISOString(),
//                     userID:resp.user.uid,
//                   });
//               })
//       }
//   });

// Sign In
export const doSignInWithEmailAndPassword = (email, password) =>
  auth.signInWithEmailAndPassword(email, password)

// Sign Out
export const doSignOut = () => auth.signOut()

// // Password Reset
// export const doPasswordReset = email =>
//   auth
//     .sendPasswordResetEmail(email)
//     .then(function() {
//       // Password reset email sent.
//     })
//     .catch(function(error) {
//       // Error occurred. Inspect error.code.
//     });

// // Password Change
// export const doPasswordUpdate = password =>
//   auth.currentUser.updatePassword(password);

// //Email Update
// export const doEmailUpdate = email =>
//   auth.currentUser.updateEmail(email).then(
//     db
//       .collection("users")
//       .doc("user3") //retrieve user name from fb
//       .update({
//         email: email
//       })
//   );

// //Update nick name
// export const doNickNameUpdate = nickname =>
//   db
//     .collection("users")
//     .doc("c")
//     .update({
//       name: nickname
//     });

// export const doBioUpdate = newBio =>
//   db
//     .collection("users")
//     .doc(localStorage.getItem("username"))
//     .update({
//       bio: newBio
//     });
