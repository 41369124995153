import * as firebase from 'firebase';
import { notification } from 'antd'
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
// require('dotenv').config();
const config = {
  // apiKey: process.env.REACT_APP_API_KEY,
  // authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  // databaseURL: process.env.REACT_APP_DATABASE_URL,
  // projectId: process.env.REACT_APP_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  apiKey: 'AIzaSyBNiOG-W5v9LuH2ULmY0tI6e_Db9j9on6o',
  authDomain: 'upbrand2.firebaseapp.com',
  databaseURL: 'https://upbrand2.firebaseio.com',
  projectId: 'upbrand2',
  // storageBucket: "upbrand2.appspot.com",
  storageBucket: 'upbrand2',
  messagingSenderId: '277852434213',
  appId: '1:277852434213:web:ed7e608a679110ba2bf4cc',
  clientID:
    '277852434213-t8gjcggvksf8g8td25smmr7k8l98nnk5.apps.googleusercontent.com',
  clientSecret: 'CwLSzH5rGJznO_mDdCdrlniO',
};

class Firebase {
  constructor() {
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.database();
    this.firestore = app.firestore();
    this.storage = app.storage();
    this.firebaseDatabase = app.database()

    /* Social Sign In Method Provider */

    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();
    this.twitterProvider = new app.auth.TwitterAuthProvider();

    /* etc */
    this.serverTimestamp = app.firestore.FieldValue.serverTimestamp;
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () =>
    this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () =>
    this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) =>
    this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT,
    });

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        this.user(authUser.uid)
          .once('value')
          .then((snapshot) => {
            const dbUser = snapshot.val();

            // default empty roles
            if ( dbUser && !dbUser.roles) {
              dbUser.roles = {};
            }

            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };

            next(authUser);
          });
      } else {
        fallback();
      }
    });

    
    currentAccount = async () => {
      console.log("iiiiiiiiiiii111")
      let userLoaded = false
      function getCurrentUser(authUser) {
        return new Promise((resolve, reject) => {
          if (userLoaded) {
            resolve(authUser.currentUser)
          }
          const unsubscribe = authUser.onAuthStateChanged(user => {
            userLoaded = true
            unsubscribe()
            const getUserData = async () => {
              if (user) {
                const userFields = await this.firebaseDatabase
                  .ref('users')
                  .child(user.uid)
                  .once('value')
                  .then(snapshot => {
                    console.log("iiiiiiiiiiii")
                    return snapshot.val()
                  })
                const mergedUser = Object.assign(user, {
                  id: user.uid,
                  name: userFields.name,
                  role: userFields.role,
                  avatar: user.photoUrl,
                })
                return mergedUser
              }
              return user
            }
            resolve(getUserData())
          }, reject)
        })
      }
      return getCurrentUser(this.auth)
    }
    

  // *** User API ***

  user = (uid) => this.db.ref(`users/${uid}`);

  users = () => this.db.ref('users');

  // *** Message API ***

  message = (uid) => this.db.ref(`messages/${uid}`);

  messages = () => this.db.ref('messages');

}
export async function currentAccount() {
  console.log("aaaa")
}
export default Firebase;

