import React from 'react'
import { connect } from 'react-redux'
import classNames from 'classnames'
import style from './style.module.scss'

const mapStateToProps = ({ settings }) => ({ settings })

// const Footer = ({ settings: { isContentMaxWidth, logo, description } }) => {
const Footer = ({ settings: { isContentMaxWidth } }) => {
  return (
    <div
      className={classNames(style.footer, {
        [style.footerFullWidth]: !isContentMaxWidth,
      })}
    >
      <div className={style.inner}>
        <div className="row">
          <div className="col-md-8">
            <p>
              <strong>WEGET Application</strong>
            </p>
            <p>
            WEGET is an experienced, certified technology solutions provider based in Bangkok, Thailand. WEGET specialization lies in developing business and custom software systems that drive efficiency and productivity in client’s business. Our Comprehensive IT & technology solutions power some of the unique and creative business enterprises.
            </p>
            <a
              href="https://weget.co.th"
              target="_blank"
              rel="noopener noreferrer"
              className={style.weget}
            >
              WEGET
              <span />
            </a>
            <br />
            <p className="mb-0">
              Copyright © 2015-{new Date().getFullYear()} WEGET |{' '}
              <a href="https://www.weget.co.th/privacy" target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
            </p>
          </div>
          <div className="col-md-4">
            <div className={style.logo}>
              <div>
                <img
                  src="app/logo_weget/android-chrome-512x512.png"
                  alt="logo"
                  style={{ maxWidth: '40px' }}
                />
              </div>
              {/* <div className={style.logo__letter}>A</div>
              <div className={style.logo__name}>{logo}</div>
              <div className={style.logo__descr}>{description}</div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(Footer)
